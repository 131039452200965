import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import styles from './covid19.module.scss';
import CovidIntro from '../components/landing-page/covid-intro';
import CovidFaq from '../components/landing-page/covid-faq';
import CovidLinks from '../components/landing-page/covid-links';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getCovidImg = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath =
    getCovidImg('covid19_OG').node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;

  return (
    <>
      <Helmet>
        <title>COVID-19 Information Hub - Trustaff </title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Check this page for updated information regarding Trustaff’s plans and procedures during the ongoing COVID-19 pandemic as well as answers to our most frequently asked questions."
        />
        <meta property="og:title" content="COVID-19 Information Hub" />
        <meta
          property="og:description"
          content="Check this page for updated information regarding Trustaff’s plans and procedures during the ongoing COVID-19 pandemic as well as answers to our most frequently asked questions."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta property="og:url" content="https://www.trustaff.com/covid19/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <CovidIntro
            care={getCovidImg('care_icon').node.childImageSharp.fluid}
            team={getCovidImg('healthcare-team').node.childImageSharp.fluid}
            facility={getCovidImg('facility').node.childImageSharp.fluid}
          />
          <CovidFaq />
          <CovidLinks
            suitcase={getCovidImg('bag').node.childImageSharp.fluid}
            doug={getCovidImg('doug').node.childImageSharp.fluid}
            stethoscope={getCovidImg('stethoscope').node.childImageSharp.fluid}
            home={getCovidImg('home').node.childImageSharp.fluid}
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^landing-page/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
