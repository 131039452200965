import React from 'react';
import Img from 'gatsby-image';
import styles from './covid-links.module.scss';

export default ({ stethoscope, home }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <div className={`${styles.home}`}>
            <hr />
            <p className={`content ${styles.sectionheader}`}>
              At Trustaff headquarters
            </p>
            <hr />
            <Img
              fluid={home}
              className={`${styles.homeimg} is-hidden-mobile`}
              alt="Trustaff headquarters"
            />
            <p className={`content`}>
              The most important thing we can do to support our travelers and
              our communities is to stay home. Social distancing efforts reduce
              the strain on our healthcare professionals, local facilities, and
              their resources.
              <br />
              <br />
              <strong>
                Most Trustaff team members are currently working remotely from
                home.
              </strong>{' '}
              We are still available 24/7 and are working diligently to support
              you in this new environment. Please join our efforts and keep
              yourselves and your families safe.
            </p>
            <div className={`${styles.embedContainer}`}>
              <iframe
                title="vimeoVideo"
                src="https://player.vimeo.com/video/403766706"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className={`${styles.linksbox}`}>
            <Img
              fluid={stethoscope}
              className={`${styles.linksimg} is-hidden-mobile`}
              alt="Stethoscope Icon"
            />
            <p className={`${styles.letterheader}`}>
              CLINICAL INFORMATION CENTER:
            </p>
            <p className={`content`}>
              •{' '}
              <a
                href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"
                target="_blank"
                rel="noreferrer"
              >
                CDC Coronavirus updates
              </a>
              <br />•{' '}
              <a
                href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019"
                target="_blank"
                rel="noreferrer"
              >
                WHO Coronavirus updates
              </a>
              <br />•{' '}
              <a
                href="https://www.jointcommissioninternational.org/en/news-and-support/coronavirus/"
                target="_blank"
                rel="noreferrer"
              >
                The Joint Commission Coronavirus updates
              </a>
              <br />•{' '}
              <a
                href="https://coronavirus.jhu.edu"
                target="_blank"
                rel="noreferrer"
              >
                Johns Hopkins Coronavirus Research Center
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
