import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './covid-intro.module.scss';

export default ({ care, team, facility }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <div className={`${styles.headline}`}>
            <Img fluid={care} className={styles.icon} alt="COVID-19 Info" />
            <h1 className={styles.header}>COVID-19 Information Hub</h1>
          </div>
          <div className={`${styles.introtext}`}>
            <p className={`content`}>
              During these unprecedented events, it is more important than ever
              to stay in close communication with our travelers. Please refer to
              this page for information regarding the pandemic and answers to
              our most frequently asked questions.
              <br />
              <br />
              <strong>
                Interested in crisis jobs or vaccination jobs for COVID-19?{' '}
                <Link to="/crisis-response/">Click here</Link>
              </strong>
            </p>
          </div>
          <div className={`${styles.letter}`}>
            <p className={`${styles.letterheader}`}>
              A NOTE ABOUT STAFFING DURING CORONAVIRUS:
            </p>
            <Img
              fluid={team}
              className={`${styles.sidebar} is-hidden-mobile`}
              alt="healthcare team"
            />
            <p className={`content`}>
              <strong>To our travelers:</strong> Whether this is your first
              assignment or your 72nd, your role in helping to fight this global
              pandemic is essential. Your skills and experience are our
              country’s most important assets.
            </p>
            <p className={`content`}>
              <strong>To our facility partners:</strong> We know your patients
              and your communities are counting on you. It's our job to help
              ease the staffing burden and bring skilled professionals to the
              frontlines to keep patient care running.
            </p>
            <Img
              fluid={facility}
              className={`${styles.sidebar} is-hidden-mobile`}
              alt="facility partner"
            />
            <p className={`content`}>
              <strong>
                We recognize the sacrifices you continue to make just to do your
                job during these unprecedented events.
              </strong>{' '}
              As you work tirelessly to provide the care patients desperately
              need, we also need to ensure that you are safe and feel secure in
              knowing that your bases are covered. Our commitment to you has
              never been more important.
              <br />
              <br />
              We are, as always, in awe of your dedication to your patients and
              your caring spirit.
              <br />
              <br />
              -- <em>Your Trustaff team</em>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
