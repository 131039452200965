import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styles from './covid-faq.module.scss';

export default ({ care, team, facility }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleKeepReadingClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <div>
            <p className={`content ${styles.header}`}>
              Frequently Asked Questions
            </p>
            <div className={`${styles.questiontime}`}>
              <p className={`content ${styles.question}`}>
                How is Trustaff handling the rapid changes due to COVID-19?
              </p>
              <div
                className={`has-text-centered-mobile ${styles.descriptions} ${
                  isExpanded ? styles.isExpanded : ''
                }`}
              >
                <p className={`content ${styles.answer}`}>
                  Trustaff has designated a multi-disciplinary team to focus all
                  efforts on the constant monitoring of the situation in
                  conjunction with our client facilities, the CDC, local health
                  departments, and other organizations like The Joint
                  Commission.
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  Will I be assisting COVID-19 patients during my assignment?
                </p>
                <p className={`content ${styles.answer}`}>
                  Even if you are not on an official COVID-19 designated
                  assignment, at one time or another there is a high likelihood
                  that you may come into contact with a potentially exposed
                  patient. We recommend everyone stay up-to-date with the latest
                  recommendations and your current facility's policies.
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  How do I notify Trustaff of a potential or known exposure?
                </p>
                <p className={`content ${styles.answer}`}>
                  If you have experienced a potential or known exposure to
                  COVID-19, please speak with your unit manager and follow any
                  procedures and guidelines provided by your current facility.
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  What should I do if I have concerns about safety at my
                  assignment?
                </p>
                <p className={`content ${styles.answer}`}>
                  We are committed to your health and safety. Our Clinical
                  Liaison team is closely following all national and local
                  updates about procedural recommendations and PPE. If you have
                  immediate concerns about your safety, please notify your
                  recruiter. A Clinical Liaison (one of our on-staff Registered
                  Nurses) will follow up shortly.
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  Can I leave my assignment early/switch to a different
                  assignment?
                </p>
                <p className={`content ${styles.answer}`}>
                  <em>The short answer is no.</em> With staffing shortages still
                  on the rise, travelers like you have never been more
                  important. Your facility, your coworkers, and the people in
                  the community where you’re working are counting on you and the
                  commitment you made to help. We will advocate for you every
                  step of the way and help prepare for what’s next.
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  What if my current assignment is cancelled?
                </p>
                <p className={`content ${styles.answer}`}>
                  Not all areas are being impacted by COVID-19 equally and
                  things are changing every day. If your assignment were to be
                  cancelled, please call your recruiter immediately so we can
                  reroute you to another position.
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  Am I an essential employee?
                </p>
                <p className={`content ${styles.answer}`}>
                  As a healthcare traveler, you are (of course) an essential
                  employee and are able to travel to and from work. If you are
                  working in an area that has issued a “stay at home” or “no
                  travel” order, under special circumstances you may be asked to
                  present your facility badge or a letter from your employer.
                  <br />
                  <br />
                  We have emailed all current Trustaff travelers a Proof of
                  Essential Employment letter. If you need a copy of the letter
                  please email{' '}
                  <a href="mailto:staffinfo@Trustaff.com">
                    staffinfo@Trustaff.com
                  </a>
                  .
                </p>
                <p className={`content ${styles.dots}`}>• • •</p>
                <p className={`content ${styles.question}`}>
                  Who do I contact if I have more questions?
                </p>
                <p className={`content ${styles.answer}`}>
                  Open lines of communication are essential during these unusual
                  times. In addition to communication from your recruiter, all
                  current travelers receive weekly email updates from{' '}
                  <a href="mailto:staffinfo@Trustaff.com">
                    staffinfo@Trustaff.com
                  </a>
                  . Please add us to your address book.
                  <br />
                  <br />
                  <strong>
                    If you have questions or concerns, speak to your recruiter
                    right away.
                  </strong>{' '}
                  Your recruiter is your best source of information. They can
                  also put you in touch with our Clinical Liaison team, Benefits
                  team, or one of our Recruiting Managers if you need additional
                  help.
                </p>
              </div>
              <div
                className={`${styles.keepReadingWrapper} ${
                  isExpanded ? styles.isExpanded : ''
                }`}
              >
                <button
                  className={`button is-info is-outlined ${styles.keepReadingBtn}`}
                  onClick={handleKeepReadingClick}
                  aria-expanded={isExpanded}
                >
                  <span>See more questions</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
